<template>
<p>Redirecting...</p>
</template>

<script>

export default {
  name: 'ScanWidthRendr',
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get('user')
    const surveyId = urlParams.get('survey')
    window.location.href = `${process.env.VUE_APP_FIREBASE_API_ROOT}/scanWithRendr?user=${userId}&survey=${surveyId}`
  }
}
</script>
